export default function saveImagesWebview(urls: string[]) {
  if ((window as any).Android && (window as any).Android.saveImages) {
    (window as any).Android.saveImages(JSON.stringify(urls));
  }

  if (
    (window as any).webkit &&
    (window as any).webkit.messageHandlers &&
    (window as any).webkit.messageHandlers.saveImages
  ) {
    (window as any).webkit.messageHandlers.saveImages.postMessage(urls);
  }
}
