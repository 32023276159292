export default function hapticWebview(type: hapticType) {
  if ((window as any).Android && (window as any).Android.haptic) {
    (window as any).Android.haptic(type);
  }

  if (
    (window as any).webkit &&
    (window as any).webkit.messageHandlers &&
    (window as any).webkit.messageHandlers.haptic
  ) {
    (window as any).webkit.messageHandlers.haptic.postMessage(type);
  }
}
