export default function saveScreenShotWebview() {
  if ((window as any).Android && (window as any).Android.saveScreenShot) {
    (window as any).Android.saveScreenShot();
  }

  if (
    (window as any).webkit &&
    (window as any).webkit.messageHandlers &&
    (window as any).webkit.messageHandlers.saveScreenShot
  ) {
    (window as any).webkit.messageHandlers.saveScreenShot.postMessage({});
  }
}
