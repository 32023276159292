
export default function shareWebview(shareData: any) {
  if (window.Android && window.Android.shareKakaoLink) {
    window.Android.shareKakaoLink(JSON.stringify(shareData));
  } else if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.shareKakaoLink
  ) {
    window.webkit.messageHandlers.shareKakaoLink.postMessage(shareData);
  }
}
