export default function closeWebview(isConfirm: boolean = false) {
  if ((window as any).Android && (window as any).Android.finish) {
    (window as any).Android.finish(isConfirm);
  }

  if (
    (window as any).webkit &&
    (window as any).webkit.messageHandlers &&
    (window as any).webkit.messageHandlers.dismiss
  ) {
    (window as any).webkit.messageHandlers.dismiss.postMessage({});
  }
}
