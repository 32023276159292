import React from "react";
import styles from "./WebviewInterfaceTest.module.scss";
import hapticWebview from "../cores/hapticWebview";
import closeWebview from "../cores/closeWebview";
import saveScreenShotWebview from "../cores/saveScreenShotWebview";
import saveImagesWebview from "../cores/saveImagesWebview";
import shareWebview from "../cores/shareWebview";

const WebviewInterfaceTest: React.FC<{}> = () => {
  // "success" | "warning" | "failure" | "interact";
  const shareData = {
    linkUrl: "http://test.link.heydealer.com/webview-interface",
    imageUrl: "https://picsum.photos/200/300",
    title: "웹뷰 테스트",
    description: "웹뷰 테스트",
    buttonText: "받으러 가기",
    imageWidth: 200,
    imageHeight: 300,
  };
  return (
    <div className={styles.webviewText}>
      <button onClick={() => closeWebview()}>close</button>
      <button onClick={() => saveScreenShotWebview()}>saveScreenShot</button>
      <button
        onClick={() =>
          saveImagesWebview([
            "https://heydealer-api-test.s3.amazonaws.com/media/cars/image/2021/09/30/15661333_762e011b-8f1d-4d94-b088-dd41f9d1ea9c.JPEG",
            "https://heydealer-api-test.s3.amazonaws.com/media/cars/image/2021/08/30/15647426_ae6268c8-3bb1-418f-9080-8b37ff24df1d.jpg",
          ])
        }
      >
        saveImages
      </button>
      <button onClick={() => shareWebview(shareData)}>shareKaKao</button>
      <div className={styles.haptic}>
        <h4>haptic</h4>
        <button onClick={() => hapticWebview("success")}>success</button>
        <button onClick={() => hapticWebview("warning")}>warning</button>
        <button onClick={() => hapticWebview("failure")}>failure</button>
        <button onClick={() => hapticWebview("interact")}>interact</button>
      </div>
    </div>
  );
};

export default WebviewInterfaceTest;
